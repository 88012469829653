<template>
  <div class="vx-row mb-12">
    <div v-if="!detail"
         v-bind:class="[
        detail ? detailShow + '  w-full mb-base' : '',
        detailHide,
      ]"
    >
      <vs-tabs  v-model="activeTab">
          <vs-tab label="Draft">
              <div class="tab-text">
              </div>
          </vs-tab>
          <vs-tab label="Confirmed">
              <div class="tab-text">
              </div>
          </vs-tab>
          <vs-tab label="Failed">
              <div class="tab-text">
              </div>
          </vs-tab>
      </vs-tabs>

      <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
          not-click-selected
          open-expand-only-td
      >
        <template slot="header">
          <div>
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

            <vs-button class="float-right ml-2"
                       readonly="readonly" @click.stop="doSearch"> Search multiple OGP</vs-button>
          </div>
        </template>
        <template slot="thead">
            <vs-th width="8%"   >
                <div class="text-center d-none"  > <vs-checkbox
                        key="checAllLaf"
                        @click.stop="SelectAll()"
                        v-model="statusSelectAll_Laf"
                        color="primary"
                >Select All</vs-checkbox
                ></div>
            </vs-th>

            <vs-td  > &nbsp; </vs-td>
            <vs-th >File Barode</vs-th>
            <vs-th >LAF</vs-th>
            <vs-th >No. Ogp</vs-th>
            <vs-th sort-key="created_at" >Created Date</vs-th>
            <vs-th sort-key="updated_at"   >Update Date</vs-th>
            <vs-th sort-key="status" >Status</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            :class="data[indextr].class"
          >
            <vs-td  >
                <div class="w-[150px]"  v-if="!disabled_detail"  >
                    <vs-checkbox
                            v-on:click="
                selectInvoice(
                  indextr,
                  data[indextr].id,
                  data[indextr]
                )
              "

                      :checked="inv_id_select.includes(tr.id)"
                      color="primary"
                    ></vs-checkbox>
                </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap"  >
              <template >
                <vx-tooltip text="Edit Document">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    v-if="checkPermission('edit') || checkPermission('view')"
                    @click.stop="
                      handleEdit(
                        data[indextr].id,
                        indextr,
                        data[indextr].file_name,
                        data[indextr].page_count,
                        data[indextr]
                      )
                    "
                    :icon="disabled_detail ? 'icon-eye' : 'icon-edit'"
                  />
                </vx-tooltip>
                <vx-tooltip  text="Delete Document" v-if="!disabled_detail && checkPermission('delete')">
                  <vs-button
                    color="danger"
                    type="line"
                    icon-pack="feather"
                    @click="deleteDraft(data[indextr].id)"
                    icon="icon-x-circle"
                  />
                </vx-tooltip>
              </template>
            </vs-td>
            <vs-td>
              <a :href="data[indextr].file_url">{{  (data[indextr].file_name) }}</a>
            </vs-td>
            <vs-td>
              {{  (data[indextr].laf) }}
            </vs-td>
            <vs-td>
              {{  (data[indextr].no_ogp) }}
            </vs-td>
            <vs-td>
              {{  dateFormat(data[indextr].created_at) }}
            </vs-td>
            <vs-td  >
              {{  dateFormat(data[indextr].updated_at) }}
            </vs-td>
            <vs-td>
              {{  (data[indextr].status) }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
    <div class="vx-row mb-2 mt-2" v-if=" selected &&  selected.length > 0">
        <div class="vx-col w-full">
            <strong class="m-2" >Selected : </strong>{{ selected.length }}
        </div>
        <div class="vx-col w-full" >
            <vs-button class="m-2 " v-if="!disabled_detail && checkPermission('release')" @click.stop="handleRelease">Confirm
            </vs-button>
        </div>
    </div>
      <transition name="detail-fade">
          <div
                  v-if="detail"
                  v-bind:class="[
          detail ? detailShow + '  w-full mb-base' : '',
          detailHide,
        ]"
          >
              <vs-row>
                  <vs-col
                          style="padding-bottom: 8px"
                          vs-offset="8"
                          vs-type="flex"
                          vs-justify="rigth"
                          vs-align="rigth"
                          vs-w="4"
                  >
                      <vs-button
                              class="ml-auto"
                              size="small"
                              v-on:click="handleClose"
                              color="grey"
                              icon-pack="feather"
                              icon="icon-x-square"
                      >Close
                      </vs-button
                      >
                  </vs-col>
                  <detail
                          v-if="tID"
                          @close="handleClose"
                          :page-count="pageCount"
                          :piId="tID"
                          :data-all.sync="dataAll"
                          :file-name="fileName"
                          :disabled_detail="disabled_detail"
                  >
                  </detail>
              </vs-row>
          </div>
      </transition>
    <div>

      <vs-prompt
        color="danger"
        title="Confirmation"
        @cancel="val = ''"
        @accept="doDelete"
        @close="closeDelete"
        :active.sync="activePromptDelete"
      >
        <div class="vx-row mb-2">
        </div>
        <div class="con-exemple-prompt">
          Are you sure to Delete LAF Barcide Document : {{ tID }} ?
        </div>
      </vs-prompt>
    </div>

      <div>
          <vs-prompt
                  color="danger"
                  title="Confirmation"
                  @cancel="val = ''"
                  @accept="doConfirm"
                  @close="closeConfirm"
                  :active.sync="activePromptConfirm"
          >
              <div class="con-exemple-prompt">
                  Are you sure confirm draft Barcode LAF  ?
              </div>
          </vs-prompt>
          <vs-prompt
                  color="danger"
                  title="Confirmation"
                  @cancel="val = ''"
                  @accept="doDelete"
                  @close="closeDelete"
                  :active.sync="activePromptDelete"
          >
              <div class="vx-row mb-2">
              </div>
              <div class="con-exemple-prompt">
                  Are you sure to Delete Draft invoice : {{ piId }} ?
              </div>
          </vs-prompt>
      </div>
      <div>
          <vs-prompt
                  color="danger"
                  title="Search multiple OGP"
                  @cancel="val = ''"
                  @accept="doSearchList"
                  @close="closeSearch"
                  :active.sync="activePromptSearch"
          >
            <div >
              <vs-input
                v-model="inputSearchOgp"
                label="Input No. OGP  (press Enter to add)"
                class="w-full"
                @keyup.enter="addListOgp"
              ></vs-input>
              <div class="pill-container">
                <div class="w-full"><strong>search list :</strong></div>
                <div
                  v-for="(pill, index) in list_ogp"
                  :key="index"
                  class="pill"
                >
                  {{ pill }}
                  <button @click="removeItemListOgp(index)">x</button>
                </div>
              </div>
            </div>
          </vs-prompt>
      </div>
  </div>
</template>
<script>
import Detail from "./detail.vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Detail,
    Datepicker,
  },
  props: {
     detailEdit:{
       type: Boolean
    },
    dataSelected: {
      type: String
    },
    optionSupplier:{
        type: Object
    },
    supplier_code: {
      type: String,
    },
    from_inv_date: {
      type: String,
    },
    to_inv_date: {
      type: String,
    },
    from_due_date: {
      type: String,
    },
    to_due_date: {
      type: String,
    },
  },
  data() {
    return {
      inv_id_select: [],
      selected: [],
      check_page: [],
      statusSelectAll_Laf: false,
      disabled_detail: false,
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      activeTab: 0,
      statusView: false,
      deleteId: null,
      tID: null,
      piId: null,
      fileName: null,
      dataItem: null,
      dataJson: null,
      dataAll: null,
      pageCount: 0,
      dataTemplate: null,
      invCode: "",
      vendor: null,
      poCode: null,
      index1: 0,
      warehouseCode: null,
      warehouseName: null,
      data: [],
      timeOut:null,
      table: {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      },
      activePromptSearch : false,
      activePromptConfirm : false,
      activePromptReject : false,
      activePromptCancel: false,
      activePromptDelete : false,
      activePrompt: false,
      activePromptReversal: false,
      createdDate: null,
      dueDate: null,
      invDate: null,
      trNumber: null,
      trDate: null,
      reversal_date: new Date(),
      disabledAfterToday: {
        from: new Date(), // Disable all dates after specific date
      },

      selectedItems: [],
      selectAll: false,
      isAllSelected: false,
      checked: [],
      selectable: true, // Enable row selection 
      canEdit: true,
      canView: true,
      canDelete: true,
      canRelease: true, 
      inputSearchOgp: '',
      list_ogp: [] 
    };
  },
  methods: {
    closeSearch(){
      this.activePromptSearch = false;
    },
    doSearch(){
      this.activePromptSearch = true;
    },
    doSearchList(){
      alert(this.list_ogp.join(","))
      this.handleSearch("")
    },
    addListOgp() {
      if (this.inputSearchOgp.trim()) {
        this.list_ogp.push(this.inputSearchOgp);
        this.inputSearchOgp = '';
      }
    },
    removeItemListOgp(index) {
      this.list_ogp.splice(index, 1);
    },
    inValidItem(items){
        if (items===null) {
            return true
        }
        if (typeof items ==="undefined"){
            return true
        }
        if (items ===""){
            return true
        }
        if (items.toString().startsWith("0001-01-01T")){
            return true
        }
        return  false
    },
    doJsonParse(strJson){
        if (typeof  strJson !== "string") {
            return strJson
        }
        try{
            return JSON.parse(strJson)
        }catch  {
            return {}
        }
    },
    checkPermission(permission) {
      try {
        return this.$store.getters && this.$store.getters['user/hasPermissions'] ? 
          this.$store.getters['user/hasPermissions'](permission) : true;
      } catch (error) {
        console.error('Error checking permission:', error);
        return true; // Default to true if there's an error
      }
    },
    doConfirm(){
        let isError = false
        let ErrorList = ""
        for (const selectedKey of this.selected) {

            if ( this.inValidItem(selectedKey.laf)) {
                ErrorList = ErrorList + "<br>&bull; Laf is empty, "
                isError = true
            }

            if ( this.inValidItem(selectedKey.no_ogp)) {
                ErrorList = ErrorList + "<br>&bull; OGP Number is empty, "
                isError = true
            }
            if (isError)  {
                break
            }
        }

        if (isError) {
            this.$vs.notify({
                color: "danger",
                title: "Error",
                text: `The selected item requires completing the necessary fields. Please edit it first.: ${ErrorList}`,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
            });

        }else {
            // eslint-disable-next-line no-constant-condition
            const ids = this.selected.map(select => select.id);
            const dataLaf = this.selected.map(select => select.laf);
            const dataOgp = this.selected.map(select => select.no_ogp);
            const dataFile = this.selected.map(select => select.file_name);
            let param = {
                id: ids,
                status: "confirm",
                data_laf: this.doJsonParse(dataLaf),
                data_no_ogp: this.doJsonParse(dataOgp),
                data_file_name: this.doJsonParse(dataFile),
                data_all: this.selected,
            };
            this.$vs.loading();
            this.$http
                .put(`/api/v1/ocr/lafs`,param)
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.code === 200) {
                        this.handleClose();
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                    this.getData(true);
                })
                .catch(() => {
                    this.$vs.loading.close();
                });
            this.selected = [];
        }
    },
    closeConfirm(){
        this.activePromptConfirm = false
    },
    handleRelease(){
        if (this.selected && this.selected.length > 0) {
            this.activePromptConfirm = true
        }
    },
    selectInvoice(index, inv_id,data_inv) {
        if (!this.inv_id_select.includes(inv_id)) {
            this.inv_id_select.push(inv_id);
            this.selected.push(data_inv);
        } else {
            var index2 = this.inv_id_select.indexOf(inv_id);
            if (index2 !== -1) {
                this.inv_id_select.splice(index2, 1);
                this.selected.splice(index2, 1);
            }
        }
    },
    SelectAll() {
        let _this = this;
        if (!this.statusSelectAll_Laf){
            this.check_page.push(this.table.page)
            this.table.data.forEach(function (element) {
                if (!_this.inv_id_select.includes(element.id)) {
                    _this.inv_id_select.push(element.id);
                    _this.selected.push(element)
                }
            });

        }else{
            var index3 = this.check_page.indexOf(this.table.page);
            if (index3 !== -1) {
                this.check_page.splice(index3, 1);
            }
            this.table.data.forEach(function (element) {
                if (_this.inv_id_select.includes(element.id)) {
                    var index2 = _this.inv_id_select.indexOf(element.id);
                    if (index2 !== -1) {
                        _this.inv_id_select.splice(index2, 1);
                        _this.selected.splice(index2, 1);
                    }
                }
            });

        }
    },
    handleOpenForm() {
      this.detail = true;
      this.detailEdit = false;
      this.status_detail = "form-create";
    },
    handleUpload(data){
      this.selectedTamplate = data;
      this.detailEdit = false;
      this.handleOpenForm();
    },
    doDelete(){
      this.$vs.loading();
      this.$http
          .delete(`/api/v1/ocr/laf/${this.tID}`,null)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    closeDelete(){
      this.tID = null;
      this.activePromptDelete = false;
    },
    deleteDraft(id){
      this.tID = id;
      this.activePromptDelete = true;
    },
    dateFormat(date) {
      if (date) {
        const inputDate = String(date).trim();

        // Format YYYY-MM-DD
        if (inputDate.includes("-")) {
          return moment(inputDate).format("YYYY-MM-DD");

          // Format DD/MM/YYYY
        } else if (inputDate.includes("/")) {
          const parts = inputDate.split("/");
          if (parts.length === 3) {
            return moment(`${parts[2]}-${parts[1]}-${parts[0]}`).format("YYYY-MM-DD");
          } else {
            return "";
          }

          // Format DD MMMM YYYY
        } else if (/\d{2}\s\w+\s\d{4}/.test(inputDate)) {
          return moment(inputDate, "DD MMMM YYYY").format("YYYY-MM-DD");

          // Format DDMMYYYY or similar
        } else {
          if (inputDate.length >= 8) {
            const day = inputDate.substr(0, 2);
            const month = inputDate.substr(2, 2);
            const year = inputDate.substr(4, 4);

            const formattedDate = `${year}-${month}-${day}`;
            return moment(formattedDate).format("YYYY-MM-DD");
          } else {
            return "";
          }
        }
      }
      return "";
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;

      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(()=>{
        this.getData(true);
      },1000)
    },
    handleChangePage(page) {
      this.statusSelectAll_Laf = this.check_page.includes(page);
      this.table.page = page;
      this.getData(false);
    },
    handleSort(key, active) {
      console.log(active, "active")
      this.table.order = key;
      this.table.sort = active;
      this.getData(true);
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData(false);
    },
    handleCloseToEdit(data){
      this.handleClose(data);
    },
    handleClose(data) {

      this.getData(true);
      if (typeof(data) != "undefined" && data!=null &&  data.id != null) {
        this.$emit("open_upload",data);
        return
      }

      this.detail = false;
    },

    getData(cleanSelect) {
      if (cleanSelect===true) {
          this.selectAll = false;
          this.inv_id_select= []
          this.selected= []
          this.check_page= []
          this.statusSelectAll_Laf= false
      }

      this.selectAll = false;
      this.$vs.loading();
      this.$http
        .get("/api/v1/ocr/laf", {
          params: {
            limit: this.table.length<5 ? 5 : this.table.length,
            page: this.table.page,
            search: this.table.search,
            ogps:   (!this.list_ogp || this.list_ogp.length === 0) ? "" : this.list_ogp.join(","),
            order: this.table.order,
            sort: this.table.sort,
            status: this.activeTab===0 ? "draft" : this.activeTab===1 ? "confirm" : "failed",
            from_date: this.from_inv_date,
            to_date: this.to_inv_date,
          },
        })
        .then((resp) => {
          if (resp.code == "200") {
            this.table.total = resp.data.record_total;
            this.table.totalPage = resp.data.page_total;
            this.table.totalSearch = resp.data.record_total_search;
            this.table.length = resp.data.record_total_per_page;
            this.table.data = resp.data.records;
            this.data = resp.data.records;
            if (this.data !=null) {

            }
          }
          this.setStartEnd();
          this.$vs.loading.close();
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleCreate() {
      this.detail = true;
    },
    isJsonString(jsonData){
      if (!jsonData) return  {}
       if (jsonData.length > 2) {
            try {
                return JSON.parse(jsonData);
            }catch  {
                return {}
            }
       }
       return {}
    },
    handleEdit(
        id,
        index,
        fileName,
        pageCount,
        dataAll
    ) {
      this.tID = id;
      this.table.data = [];
      this.table.data = this.data;
      this.pageCount = pageCount;
      this.fileName = fileName;
      this.dataAll = dataAll;

      this.table.data[this.index1].class = "";
      this.table.data[index].class = "highlight";
      this.detail = true;
      this.index1 = index;
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch("user/getPermissions","vendor-invoice-ocr");
      this.canEdit = this.checkPermission('edit');
      this.canView = this.checkPermission('view');
      this.canDelete = this.checkPermission('delete');
      this.canRelease = this.checkPermission('release');
    } catch (error) {
      console.error("Error getting permissions:", error);
      // Handle error appropriately
    }
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {
      activeTab: function (){
          // this.table.data = []
          // this.table.length = 10
          // this.table.page = 1
          // this.table.search = ""
          // this.table.start= 1
          // this.getData(true)
          // this.disabled_detail = this.activeTab === 1
      }
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}
 .pill-container {
   margin-top: 10px;
 }
.pill {
  display: inline-block;
  background-color: #007bff;
  color: white;
  border-radius: 20px;
  padding: 5px 10px;
  margin: 5px;
}
.pill button {
  margin-left: 5px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}
.pill button:hover {
  text-decoration: underline;
}
</style>
