<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style>
<template>
  <div class=" vx-row w-full  ml-auto mr-auto">
    <div class="vx-col w-1/2 ">
        <div ref="targetDiv" class="target-div">  </div>
        <span>
            <h4 class="mb-5">Supplier Invoice OCR</h4>
        </span>
      <div :class="{ 'fixed-div': isFixed }">
      <div class="row bg bg-light   card" style="padding-bottom: 60px;">
        <div class="col-md-10 offset-md-1">
          <div class="flex-container  w-full" >
            <div class="flex-item" style="flex: 2;">
              <vx-card style="max-height: 600px;overflow: scroll; width: 200px" >
                <img  v-for="(src, index) in optionImageThub" :key="index" :src="src" width="150px" alt="page-1" @click.stop="previewImage(index)"
                     :class="{ selected: selectedImageIndex === index }"   />
                <span  v-if="!optionImageThub" class="box-loading"  >Loading..</span>
              </vx-card>
            </div>
            <div class="flex-item" style="flex: 8;">
              <div class="vs-row w-[600px]">
                <vue-cutter
                  class="vx-col w-[600px]"
                  ref="cutter"
                  :container-bounding="optionCutter.bounding"
                  :crop-box-bounding="optionCutter.cropB"
                  :crop-dividing-line="true"
                  :src="optionCutter.src"
                  :crop-info="true"
                  mode="contain"
                  @preview="preview"
                  @imgLoad="imgLoad"
                ></vue-cutter>
                <vs-row
                  style="top: -70px;left: -60px; position: relative"
                  vs-align="flex-start"
                  vs-type="flex" vs-justify="center" vs-w="12">
                  <vs-col  vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                    <vs-button radius color="primary" class=" m-1" type="filled" icon="undo"  @click.stop="doRotate(-1)" ></vs-button>
                    <vs-button radius color="success" class=" m-1"  type="filled" icon="autorenew"  @click.stop="doRotate(0)" ></vs-button>
                    <vs-button radius color="primary" class=" m-1"  type="filled" icon="redo"  @click.stop="doRotate(1)"> </vs-button>
                  </vs-col>
                </vs-row>
              </div>
              <div class="showBox p-2" style="top:-60px; position: relative" v-if="imageload" >
                <vs-button class="float-right ml-2"
                           :disabled="disabled_detail || ocr_var===null"
                           readonly="readonly" @click.stop="getOcr"> OCR</vs-button>
                <vs-button class="float-right ml-2"
                           :disabled="disabled_detail"
                           readonly="readonly" @click.stop="getQR"> QR Reader</vs-button>
                <div class="imgCutDownBoxContainer " v-html="preHtml"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div class="vx-col w-1/2 mt-2">
    <span>
      <h4 class="mb-5"></h4>
    </span>
      <div class="vx-row">
        <div class="vx-col w-full">
          <span>Priority</span>
          <multiselect v-model="dataAll.priority"
                       :disabled="disabled_detail"
                       :readonly="disabled_detail"
                       key="priority" :options="priorities" >
          </multiselect>
        </div>

        <div class="vx-col w-full mb-2">
          Scan Date
          <datepicker
              class="w-full"
              :inline="false"
              label="Scan Date"
              v-model="dataAll.scan_at"
              :disabled="true"
              :readonly="true"
              format="yyyy-MM-dd"
          ></datepicker>
        </div>
        <div class="vx-col w-full mb-2">
          Created Date
          <datepicker
              class="w-full"
              :inline="false"
              label="Created Date"
              v-model="dataAll.created_at"
              :disabled="true"
              :readonly="true"
              format="yyyy-MM-dd"
          ></datepicker>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <vs-input
              class="w-full "
              label="PIC"
              name="pic_name"
              :disabled="disabled_detail"
              readonly="readonly"
              v-model="dataAll.pic['name']"
          />
        </div>

        <div class="vx-col w-full mb-2">
          <vs-input
            class="w-full "
            label="PIC Phone"
            name="pic_name"
            :disabled="disabled_detail"
            readonly="readonly"
            v-model="dataAll.pic['Mobile']"
          />
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full" v-if="optionSupplier">
          <span>Supplier</span>
          <multiselect
              class="selectExample"
              key="supplier_id"
              v-model="dataAll.supplier"
              :options="optionSupplier"
              :multiple="false"
              :allow-empty="true"
              :group-select="false"
              :max-height="160"
              :limit="4"
              placeholder="Select Supplier"
              track-by="ID"
              label="Name"
              :disabled="true"
              readonly="readonly"
          >
            <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
            </span>
            </template>

            <template slot="option" slot-scope="dt">
              <div class="option__desc">
                <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="vx-row" style="display: none;">
        <div class="vx-col w-full mb-2">
          <label class="vs-input--label">Supplier Bank</label>
          <multiselect
              class="selectExample"
              key="bank_id"
              v-model="dataAll.data_bank"
              :options="optionBankSupplier"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="160"
              :limit="4"
              :disabled="disabled_detail"
              :readonly="disabled_detail"
              track-by="ID"
              label="DisplayName"
              :required="true"
          >
          </multiselect>
          <span class="text-danger text-sm"
            v-if="isEmptyObject(dataAll.data_bank) && status_submit"
        >This field is required</span>
        </div>
      </div>
      <div class="vx-row ">
        <div class="vx-col w-full mb-2">
          <vs-input
            class="w-full "
            label="Tax Number"
            name="nomor_seri"
            @click.stop="setFocusedInput('nomor_seri')"
            v-model="dataValid.nomor_seri"
            :disabled="disabled_detail"
            :readonly="disabled_detail"
            :required="true"
            @input="handleNumericInput(dataValid.nomor_seri,'nomor_seri')"
          />
          <span
            class="text-danger text-sm"
            v-if="dataValid.nomor_seri == null && status_submit"
        >This field is required</span>
        </div>
        <div class="vx-col w-full mb-2">
          Tax Date
          <datepicker
            class="w-full"
            :inline="false"
            label="Tax Date"
            v-model="dataValid.tax_date"
            placeholder="Select Tax Date"
            :disabled="disabled_detail"
            :readonly="disabled_detail"
            :disabled-dates="disabledDatesTaxDate"
            @click.stop="setFocusedInput('tax_date')"
            format="yyyy-MM-dd"
            :required="true"
          ></datepicker>
          <span
            class="text-danger text-sm"
            v-if="dataValid.tax_date === '' && status_submit"
        >This field is required</span>
        </div>
        <div class="vx-col w-full mb-2">
          <vs-input
            class="w-full "
            label="Tax Value"
            name="tax_value"
            @click.stop="resetOcr"
            v-model="dataValid.tax"
            @input="validateInputTaxValue"
            :disabled="disabled_detail"
            :required="true"
          />
          <span
              class="text-danger text-sm"
              v-if="dataValid.tax === '' && status_submit"
          >This field is required</span>

        </div>
        <div class="vx-col w-full mb-2">
          <vs-input
            class="w-full "
            label="DPP"
            name="dpp"
            v-model="dataValid.dpp"
            @input="validateInputDPP"
            @click.stop="resetOcr"
            :disabled="disabled_detail"
            :required="true"
          />
          <span
            class="text-danger text-sm"
            v-if="dataValid.dpp === '' && status_submit"
          >This field is required</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <vs-input
            class="w-full "
            label="PO Number"
            name="po_code"
            v-model="dataValid.po"
            :disabled="disabled_detail"
            :readonly="disabled_detail"
            @click.stop="setFocusedInput('po')"
            placeholder="PO Number"
          />
        </div>
        <div class="vx-col w-1/2 mb-2">
          PO Date
          <datepicker
            class="w-full"
            :inline="false"
            label="Po Date"
            v-model="dataValid.po_date"
            placeholder="Select PO Date"
            :disabled="disabled_detail"
            :readonly="disabled_detail"
            @click.stop="setFocusedInput('po_date')"
            format="yyyy-MM-dd"
          ></datepicker>
        </div>
      </div>


      <vs-divider style="width: 100%; margin-left: 2%">Invoice Data</vs-divider>
      <div class="vx-row  ">

        <div class="vx-col w-full mb-2">
          <div class="vx-col w-full mb-2">
            <span>Trade Invoice</span>
            <div class="vx-col w-full mb-2">
              <vs-radio v-model="dataValid.invoice_group"
                        :disabled="disabled_detail"
                        :required="true"
                        :readonly="disabled_detail" vs-value="Trade" class="mr-4" vs-name="footer-type-hidden">Trade</vs-radio>
              <vs-radio v-model="dataValid.invoice_group"
                        :disabled="disabled_detail"
                        :required="true"
                        :readonly="disabled_detail" vs-value="Non Trade" class="mr-4" vs-name="footer-type-hidden">Non Trade</vs-radio>

            </div>
            <span
                class="text-danger text-sm"
                v-if="dataValid.invoice_group === true && status_submit"
            >This field is required</span>
          </div>

          <div class="vx-col w-full mb-2">
          <span>Invoice Type</span>
            <multiselect v-model="dataValid.invoice_type"
                         :disabled="disabled_detail"
                         :readonly="disabled_detail"
                         :required="true"
                       key="invoice_type" :options="optionsInvoiceType"
                       placeholder="Select Invoice Type"></multiselect>
          </div>
          <span
              class="text-danger text-sm"
              v-if="dataValid.invoice_type === '' && status_submit"
          >This field is required</span>
        </div>
        <div class="vx-col w-full mb-2">
          <vs-input
            class="w-full "
            label="Reference Number"
            name="invoice_number"
            @click.stop="setFocusedInput('no')"
            v-model="dataValid.no"
            :disabled="disabled_detail"
            :readonly="disabled_detail"
            type="text"
            :required="true"
          />  <span
            class="text-danger text-sm"
            v-if="dataValid.no === '' && status_submit"
        >This field is required</span>
        </div>
        <div class="vx-col w-full mb-2">
          <vs-input
            class="w-full  "
            label="External Code"
            name="external_code"
            :disabled="disabled_detail"
            :readonly="disabled_detail"
            @click.stop="setFocusedInput('external_code')"
            v-model="dataValid.external_code"
            :required="true"
          />
          <!-- <span
            class="text-danger text-sm"
            v-if="dataValid.external_code === '' && status_submit"
        >This field is required</span> -->
        </div>
        <div class="vx-row w-full ml-1  mb-2">
          <div class="vx-col w-1/2">
              <vs-input
                class=" w-full "
                label="Barcode"
                name="barcode"
                v-model="dataAll.barcode"
                :disabled="disabled_detail"
                :readonly="disabled_detail"
                @click.stop="setFocusedInput('barcode')"
              />
            <span
                class="text-danger text-sm"
                v-if="dataAll.barcode === '' && status_submit"
            >This field is required</span>
          </div>
          <div class="vx-col w-1/3 mt-6">
                <vs-button class="mr-3 mb-2 w-full"
                           :disabled="disabled_detail"
                           :readonly="disabled_detail" @click.stop="getBarcode(true)">Generate Barcode </vs-button>
          </div>
        </div>

        <div class="vx-col w-full mb-2">
          <vs-input
            ref="dppInput"
            class="w-full "
            label="DPP Invoice"
            name="dpp_invoice"
            v-model="dataValid.dpp_invoice"
            @input="validateInputDppInvoice"
            @click.stop="resetOcr"
            :disabled="disabled_detail"
            :required="true"
            outlined
          />
          <span
            class="text-danger text-sm"
            v-if="Number(dataValid.dpp_invoice) === 0 && status_submit"
          >This field is required and cannot be zero</span>
        </div>
        <div class="vx-col w-full mb-2">
          <vs-input
            ref="grossInput"
            class="w-full "
            label="Gross Amount"
            name="gross"
            v-model="dataValid.gross"
            @input="validateInputGross"
            @click.stop="resetOcr"
            :disabled="disabled_detail"
            :required="true"
            outlined
          />
          <span
            class="text-danger text-sm"
            v-if="Number(dataValid.gross) === 0 && status_submit"
          >This field is required and cannot be zero</span>
        </div>


        <div class="vx-col w-full mb-2">
          Invoice Date
          <datepicker
            class="w-full"
            :inline="false"
            :disabled="disabled_detail"
            :readonly="disabled_detail"
            label="Invoice Date"
            name="invoice_date"
            v-model="dataValid.dndate"
            @click.stop="resetOcr"
            placeholder="Select Invoice Date"
            format="yyyy-MM-dd"
            :required="true"
          ></datepicker>
          <span
              class="text-danger text-sm"
              v-if="dataValid.dndate === '' && status_submit"
          >This field is required</span>
        </div>

        <div class="vx-col w-full mb-2">
          Invoice Due Date
          <datepicker
            class="w-full"
            :inline="false"
            label="Invoice Due Date"
            v-model="dataValid.duedate"
            placeholder="Select Invoice Due Date"
            @click.stop="resetOcr"
            format="yyyy-MM-dd"
            :readonly="disabled_detail"
            :disabled="disabled_detail"
            :required="true"
          ></datepicker>
          <span
            class="text-danger text-sm"
            v-if="dataValid.duedate === '' && status_submit"
        >This field is required</span>
        </div>

        <div class="vx-col w-full mb-2">
          <span>Internal Memo</span>
          <vs-checkbox v-model="dataAll.data_note['internal_memo']"
                       :disabled="disabled_detail"
                       :readonly="disabled_detail" >
            <span>{{ internal_memo }}</span>
          </vs-checkbox>
        </div>

        <div class="vx-col w-full mb-2">
          BAPB Date
          <datepicker
              class="w-full"
              :inline="false"
              label="BAPB Date"
              :disabled="disabled_detail"
              :readonly="disabled_detail"
              @click.stop="resetOcr"
              :disabled-dates="disabledDatesBAPB"
              v-model="bapb"
              placeholder="Select Tax Date"
              format="yyyy-MM-dd"
          ></datepicker>
        </div>

        <div class="vx-col w-full mb-2">
          Receiving Date
          <datepicker
              class="w-full"
              :inline="false"
              label="Tax Date"
              :disabled="disabled_detail"
              :readonly="disabled_detail"
              @click.stop="resetOcr"
              v-model="dataAll.receive_at"
              placeholder="Select Receive Date"
              format="yyyy-MM-dd"
              :required="true"
          ></datepicker>
          <span
              class="text-danger text-sm"
              v-if="dataAll.data_note === '' && status_submit"
          >This field is required</span>

        </div>

      </div>
      <vs-divider style="width: 100%; margin-left: 2%"> </vs-divider>
      <div class="vx-row mb-2 mt-6">
        <div class="vx-col w-full"><b>QR-Link</b>
          <vs-textarea
                       :disabled="true"
                       :readonly="true"
                       v-model="dataAll.qr_link"    />
          <span
              class="text-danger text-sm"
              v-if="dataAll.qr_link === '' && status_submit"
          >This field is required</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <label class="vs-input--label">Refer to Role</label>
          <multiselect  v-if="optionRoleRefer"
            class="selectExample"
            v-model="selectedRole"
            :options="optionRoleRefer"
            :multiple="false"
            :allow-empty="true"
            :group-select="false"
            :max-height="160"
            :limit="4"
                        @click.stop="resetOcr"
                        placeholder=" Type to search"
            label="display_name"
                        :disabled="disabled_detail"
                        :readonly="disabled_detail"
          >
            <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.display_name }}</span>
            </span>
            </template>

            <template slot="option" slot-scope="dt">
              <div class="option__desc">
                <span class="option__title">{{ dt.option.display_name }}</span>
              </div>
            </template>
          </multiselect>

        </div>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"><b>Notes</b></vs-divider>
      <div class="vx-row ml-4 mb-2 mt-2">
       <template v-for="note in notes">
         <div v-bind:key="note" class="w-full"><strong>{{note}}</strong>
         </div>
       </template>
      </div>
      <vs-textarea
        v-model="new_notes"
        :disabled="disabled_detail"
        :readonly="disabled_detail"
      />
      <div class="vx-row mb-2 mt-6">
        <div class="vx-col w-full">
          <vs-button class="mr-3 mb-2"
                    v-if="checkPermission('edit')"
                     :disabled="disabled_detail"
                     :readonly="disabled_detail" @click.stop="handleUpdate">Update
          </vs-button>
        </div>
      </div>
    </div>
    <div v-if="use_item" class="vc-col w-full">
      <vs-divider style="width: 100%; margin-left: 2%"
      >List Item Invoice
      </vs-divider
      >
      <div>
        <vs-table>
          <vs-tr>
            <vs-th>Item Code</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>UOM</vs-th>
            <vs-th>Qty</vs-th>
            <vs-th>Unit Price</vs-th>
            <vs-th>Sub Total</vs-th>
            <vs-th>Discount</vs-th>
            <vs-th>Net</vs-th>
            <vs-th>Tax</vs-th>
            <vs-th>Total</vs-th>
          </vs-tr>
          <vs-tr v-for="(item, index) in dataValid.product_item" :key="index">
            <vs-td>
              <vs-input v-model="item.product_id" type="text"></vs-input>
            </vs-td>
            <vs-td>
              <vs-input v-model="item.item_name" type="text"></vs-input>
            </vs-td>
            <vs-td>
              <vs-input v-model.number="item.item_unit" type="text"></vs-input>
            </vs-td>
            <vs-td>
              <vs-input v-model.number="item.quantity" type="number"></vs-input>
            </vs-td>
            <vs-td>
              <vs-input v-model.number="item.price" type="number"></vs-input>
            </vs-td>
            <vs-td>
              <vs-input v-text="numberFormat(hitungSubtotal(item,false))" readonly="" class="input-readonly" type="number"></vs-input>
            </vs-td>
            <vs-td>
              <vs-input v-model.number="item.discount" type="number"></vs-input>
            </vs-td>
            <vs-td>
              <vs-input v-text="numberFormat(hitungSubtotal(item,true))"  readonly=""   type="text"></vs-input>
            </vs-td>
            <vs-td>
              <vs-input v-model.number="item.tax" type="number"></vs-input>
            </vs-td>
            <vs-td>
              <vs-input v-model.number="item.price_discount" type="number"></vs-input>
            </vs-td>
          </vs-tr>
        </vs-table>
      </div>
    </div>

  </div>

</template>
<script>
import Datepicker from "vuejs-datepicker";
import {VueCutter} from 'vue-cutter'
import {Int} from "vuecode.js";
import axios from "axios";
import moment from "moment";

export default {
  components: {
    Datepicker,
    VueCutter,
  },
  props: {

    dataItem: {
      type: [],
    },
    fileName: {
      type: String,
    },
    dataJSON: {
      type: Object
    },
    dataAll: {
      type: Object
    },
    page: {
      type: Int,
    },
    pageCount: {
      type: Int,
    },
    dataValid: {
      type: Object
    },
    dataReferTo: {
      type: Object
    },
    dataInvoice: {
      type: String
    },
    dataGR: {
      type: String
    },
    dataPO: {
      type: String
    },
    dataSupplier: {
      type: String
    },
    dataBank: {
      type: String
    },
    piId: {
      type: Number,
    },
    pic_name: {
      type: String,
    },
    pic_phone: {
      type: String,
    },
    tax_date: {
      type: String,
    },
    createdDate: {
      type: String,
    },
    dueDate: {
      type: String,
    },
    po_date: {
      type: String,
    },
    gr_code: {
      type: String,
    },
    gr_date: {
      type: String,
    },
    external_code: {
      type: String,
    },
    priority: {
      type: String,
    },
    qr_link: {
      type: String,
    },
    barcode: {
      type: String,
    },
    payment_term: {
      type: String,
    },
    new_notes: {
      type: String,
    },
    ocr_value: {
      type: String,
    },
    disabled_detail: {
      type: Boolean,
    },
  },
  created() {
    this.selectedPages = [this.pageOptions[0]];
  },
  data() {
    return this.initialState();
  },
  methods: {
    getValueFromObject(objJson, keyVar){
      if (objJson && typeof objJson!== 'undefined' && objJson!==""){
        let jsonData = JSON.parse(objJson)
        return  jsonData[keyVar]
      }
      return ""
    },
    getOptionSupplierBank() {
       this.$http.get(`/api/v1/pi/supplier-account-with-territory/${this.dataAll.supplier.ID}`  ).then((r) => {
      //this.$http.get(`/api/v1/pi/supplier-account-with-territory/1`  ).then((r) => {
        this.optionBankSupplier = r.data.account
        for (let i = 0; i <this.optionBankSupplier.length ; i++) {
          this.optionBankSupplier[i]["DisplayName"] = `${this.optionBankSupplier[i].BankName} ${this.optionBankSupplier[i].AccountNumber}  (${this.optionBankSupplier[i].AccountName}) - ${this.optionBankSupplier[i].code}  ${this.optionBankSupplier[i].name}`
        }
      }).catch((e) => {
        console.log(e)
      })

    },
    getRefferRole() {
      console.log("ROLE")
      this.$vs.loading();

      this.$http.get(`/api/v1/pi/get-ods-role`  ).then((r) => {
          this.optionRoleRefer = r.data.map(role => ({
            display_name: role.DisplayName,
            name: role.Name,
            id: role.ID
          }));

      }).catch((e) => {
        console.log(e)
      })

    },
    hitungSubtotal(item,useDiskon){
      var price = parseInt(item.price.toString().replace(".",""))
      var qty = parseInt(item.quantity )
      if (!useDiskon) {
        return   qty * price;
      }
      var discount = parseFloat(item.discount.toString().replaceAll(',','.'));
      var subTotal = (( qty * price) - (qty * price * discount /100));
      return this.numberFormat(subTotal) ;
    },
    formatDecimal(input) {
      if (!input || typeof input === "undefined" || input ===null){
        return 0
      }
      // Determine the thousand and decimal separators
      let thousandSeparator, decimalSeparator;
      const commaCount = (input.match(/,/g) || []).length;
      const periodCount = (input.match(/\./g) || []).length;

      if (commaCount > periodCount) {
        // More commas, so commas are thousand separators and periods are decimal separators
        thousandSeparator = '.';
        decimalSeparator = ',';
      } else if (periodCount > commaCount) {
        // More periods, so periods are thousand separators and commas are decimal separators
        thousandSeparator = ',';
        decimalSeparator = '.';
      } else {
        // Default assumption
        thousandSeparator = ',';
        decimalSeparator = '.';
      }

      // Normalize the number to have periods as thousand separators and commas as decimal separators
      let parts = input.split(decimalSeparator);
      let integerPart = parts[0].replace(new RegExp(`\\${thousandSeparator}`, 'g'), '');
      let fractionalPart = parts[1] ? parts[1] : '';

      // Format the integer part with thousand separators
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // Combine the integer part and the fractional part with a comma separator
      return fractionalPart ? `${integerPart}.${fractionalPart}` : integerPart;
    },
    formatCurrency(value) {
        return  (this.formatDecimal(value))
    },
    validateInputGross(value) {
      if (!value || typeof value ==="undefined") {
        this.dataValid["gross"] = 0
        // value = "0"
      }
      // Hanya izinkan angka dan titik desimal
      const validValue = value.replace(/[^0-9.]/g, '');
      const parts = validValue.split('.');
      if (parts.length > 2) {
        this.dataValid.gross = parts[0] + '.' + parts.slice(1).join('');
      } else {
        this.dataValid.gross = validValue;
      }
      if (validValue.toString().endsWith(".")){
        this.dataValid.gross = value.replace(/[^0-9.]/g, '');
      }else {
        this.dataValid.gross = this.formatNumber(this.dataValid.gross);
      }
    },
    validateInputDppInvoice(value) {
      if (!value || typeof value ==="undefined") {
        this.dataValid["dpp_invoice"] = 0
        // value = "0"
      }
      // Hanya izinkan angka dan titik desimal
      const validValue = value.replace(/[^0-9.]/g, '');
      const parts = validValue.split('.');
      if (parts.length > 2) {
        this.dataValid["dpp_invoice"] = parts[0] + '.' + parts.slice(1).join('');
      } else {
        this.dataValid["dpp_invoice"] = validValue;
      }
      if (validValue.toString().endsWith(".")){
        this.dataValid["dpp_invoice"] = value.replace(/[^0-9.]/g, '');
      }else {
        this.dataValid["dpp_invoice"] = this.formatNumber(this.dataValid["dpp_invoice"]);

      }
    },validateInputDPP(value) {
      if (!value || typeof value ==="undefined") {
        this.dataValid["dpp"] = 0
        // value = "0"
      }
      // Hanya izinkan angka dan titik desimal
      const validValue = value.replace(/[^0-9.]/g, '');
      const parts = validValue.split('.');
      if (parts.length > 2) {
        this.dataValid["dpp"] = parts[0] + '.' + parts.slice(1).join('');
      } else {
        this.dataValid["dpp"] = validValue;
      }
      if (validValue.toString().endsWith(".")){
        this.dataValid["dpp"] = value.replace(/[^0-9.]/g, '');
      }else {
        this.dataValid["dpp"] = this.formatNumber(this.dataValid["dpp"]);
      }
    },
    validateInputTaxValue(value) {
      if (!value || typeof value ==="undefined") {
        this.dataValid["tax"] = 0
        // value = "0"
      }
      // Hanya izinkan angka dan titik desimal
      const validValue = value.replace(/[^0-9.]/g, '');
      const parts = validValue.split('.');
      if (parts.length > 2) {
        this.dataValid["tax"] = parts[0] + '.' + parts.slice(1).join('');
      } else {
        this.dataValid["tax"] = validValue;
      }
      if (validValue.toString().endsWith(".")){
        this.dataValid["tax"] = value.replace(/[^0-9.]/g, '');
      }else {
        this.dataValid["tax"] = this.formatNumber(this.dataValid["tax"]);
      }
    },
    formatNumber(value) {
      // Pisahkan bagian angka dan desimal
      let [integerPart, decimalPart] = value.split('.');

      // Format bagian angka dengan pemisah ribuan
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // Gabungkan kembali angka dan bagian desimal (jika ada)
      return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
    },
    zestCurrency(number){
      // Periksa apakah input kosong, null, atau undefined
      if (number === null || typeof number === "undefined" || number === "") {
        return "0";
      }

      // Menghapus koma ribuan dan mengganti koma dengan titik untuk parsing angka
      let strNumber = number.toString().replace(/,/g, '').replace('.', ',');

      // Memeriksa apakah input adalah angka yang valid
      if (isNaN(strNumber.replace(',', '.'))) {
        return "0";
      }
      return parseFloat(strNumber)
        .toFixed(2)
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    numberFormat(number) {
          // Periksa apakah input kosong, null, atau undefined
          if (number === null || typeof number === "undefined" || number === "") {
              return "0";
          }

          // Menghapus titik ribuan dan mengganti koma dengan titik untuk parsing angka
          let strNumber = number.toString().replace('.', '').replace(/\./g, '').replace(',', '.');

          // Memeriksa apakah input adalah angka yang valid
          if (isNaN(strNumber)) {
              return "0";
          }

          // Parse string ke float
          let parsedNumber = parseFloat(strNumber);

          // Menggunakan Intl.NumberFormat untuk memformat angka ke format ribuan
          let formatter = new Intl.NumberFormat('id-ID');

          // Memformat angka ke format ribuan
          let formattedNumber = formatter.format(parsedNumber);

          // Memeriksa apakah input adalah desimal
          if (parsedNumber % 1 !== 0) {
              // Jika desimal, bulatkan ke bawah dan tambahkan ",00"
              formattedNumber = formatter.format(Math.floor(parsedNumber)) + ",00";
          } else {
              // Jika bukan desimal, format tanpa digit desimal
              // formattedNumber = formattedNumber.replace('.', ',');
          }

          return formattedNumber;
      },
    setFocusedInput(varName){
       this.ocr_var = varName;
    },
    handleNumericInput(event,idx){
      setTimeout(()=>{
        var amount = Number(String(event).replace(/\D/g, ''));
        if (!isNaN(amount)) {
          this.dataValid[idx] = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          this.dataValid[idx] = 0;
        }
      },5);
    },
    previewImage(idx){
      this.optionCutter.src = this.optionImageThub[idx];
      this.selectedImageIndex = idx;
    },
    doRotate(mode){
      if (mode===-1){
        this.$refs.cutter.rotateLeft()
      }else if (mode===1){
        this.$refs.cutter.rotateRight()
      }else {
        this.$refs.cutter.rotateClear()
      }
    },
    resetOcr(){
      this.ocr_var = null
    },
    getOcr() {
      if (!this.ocr_var) {
        return
      }
      let varData =this.ocr_var
      this.$refs.cutter.getBase64Data(data => {
        let image64 = data.split(",")
        this.getTextOcr(image64[1], varData)
      })
    },
    getQR() {
      this.$refs.cutter.getBase64Data(data => {
        let image64 = data.split(",")
        this.getTextQR(image64[1])
      })
    },
    handleSelectionChangePage(newValues) {
      this.getPdfS3Image(newValues.page);
    },
    customLabelPage(option) {
      return `Page ${option.page}`;
    },
    addDays(dateStr, days) {
      return moment(dateStr).add(days, 'days').format('YYYY-MM-DD');
    },
    getOptionSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/supplier-payment-term", {
          params: {
            id :  this.dataAll.supplier.ID
          },
          order: "name",
          sort: "asc",
        })
        .then((resp) => {
          if (resp.code === 200) {
            this.optionSupplier =  resp.data ;
            this.dataValid.duedate = this.dataValid.dndate;
            var topDate = 0
            if (typeof (this.dataAll.supplier.ID) !== "undefined") {
              if (this.optionSupplier!==null && this.optionSupplier.length) {
                topDate = this.optionSupplier[0]["due_invoice_period"] ;
                this.dataValid.duedate = this.addDays(this.dataValid.duedate, topDate)
              }
            }

          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    initialState() {
      return {
        canEdit: true,
        ocr_var:null,
        bapb:null,
        use_item:false,
        isFixed:false,
        optionRole:null,
        optionRoleRefer:null,
        selectedRole:null,
        selected:null,
        selectedImageIndex:-1,
        selectedBank:null,
        trade_type: "Trade",
        internal_memo: "with internal memo",
        supplierBank: null,
        supplier: null,
        optionBankSupplier: [],
        optionSupplier: null,
        selectedPriority: null,
        priorities: ['standart', 'urgent', 'very urgent'],
        selectedInvoiceTypeValue: null,
        selectedInvoiceGroupValue: null,
        optionsInvoiceType: [
          'invoice',
          'advance'
        ],
        optionsIternalMemo: [
          'with',
          'without'
        ],
        optionsIvoiceGroup: [
          'trade',
          'non trande'
        ],
        selectedPages: [],
        pageOptions: Array.from({length: this.pageCount}, (_, index) => ({page: index + 1})),
        optionCutter: {
          bounding: '600px',
          src: "",
          cropB: '20%',
          imageload: ''
        },
        imageload: false,
        optionImageThub: null,
        showLoader: true,
        preHtml: '',
        remotePic: '',
        optionPaymentTerm: [],
        optionBankName: [],
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        notes: "",
        table: {
          inv_code: null,
          inv_date: null,
          payment_term: null,
          bank_name: null,
          account_name: null,
          account_number: null,
          total_inv_value: 0,
          item_value: 0,
          discount_value: 0,
          charges_value: 0,
          tax_value: 0,
          data: [],
          data_charges: [],
          file: [],
        },
        net: [],
        total: [],
        gr_codes: "",
        due_date: null,
        tr_number: null,
        tr_date: null,
        disabledDatesBAPB: {
          to: null,
          from: null,
          customPredictor: function(date) {
            const today = new Date();
            const threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(today.getMonth() - 3);
            return date.getDate() > threeMonthsAgo  ;
          }
        },
        disabledDatesTaxDate: {
          to: null,
          from: null,
          customPredictor: function(date) {
            const today = new Date();
            const threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(today.getMonth() - 3);
            return date.getDate() > threeMonthsAgo  ;
          }
        },
        topDate: 0,
      };
    },
    setMinMaxDates() {
      const today = new Date();
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(today.getMonth() - 3);
      this.disabledDatesTaxDate.to = threeMonthsAgo;

      this.disabledDatesTaxDate.customPredictor= function(date) {
        const today = new Date();
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(today.getMonth() - 3);
        return date.getDate() > threeMonthsAgo  ;
      }
      this.disabledDatesBAPB.to = threeMonthsAgo;
    },
    preview(data) {
      this.preHtml = data.html
    },
    imgLoad(params) {
      this.optionCutter.imageload = params
    },
    appendNoteWithTimestamp(note) {

        this.dataAll.notes =  note
        const now = new Date();

        // Format tanggal dalam YYYY-MM-DD
        const date = now.toLocaleDateString('id-ID', { year: 'numeric', month: '2-digit', day: '2-digit' })
            .replace(/\//g, '-'); // Ganti '/' dengan '-'

        // Format waktu dalam HH:MM:SS
        const time = now.toLocaleTimeString('id-ID', { hour12: false });

        // Format timestamp
        const timestamp = `${date} ${time} (GMT+7)`;
        const formattedNote = `${timestamp} Supplier Invoice OCR : ${note}; `;

    },
    paramData() {
      let  newStatus = this.dataAll.status !== "draft" ? this.dataAll.status : "draft"
      this.dataValid.dndate = this.convertDate(this.dataValid.dndate);
      this.dataValid.duedate = this.convertDate(this.dataValid.duedate);
      this.dataValid["barcode"] = this.dataAll["barcode"];
      this.dataValid["data_bank"] = this.dataAll["data_bank"];
      if (this.selectedRole !== null) {
        this.dataAll["refer_to"] = this.selectedRole["id"];
        this.dataValid["refer_to"] = this.selectedRole["id"];
        this.dataValid["refers_to"] = this.selectedRole;
      }
      if (typeof this.dataAll.data_note==="undefined" || this.dataAll.data_note===""){
        this.dataAll.data_note =  {bapb:"",receiving_date:"",internal_memo:""}
      }

      this.dataValid.gross = this.dataValid.gross.replace(/,/g, '')

      return {
        pi_id: this.piId,
        data_valid: this.dataValid,
        data_all: this.dataAll,
        notes: this.new_notes,
        status:newStatus,
        refer_to:this.selectedRole,
      };
    },
    postData() {
      this.$http
        .put(`/api/v1/ocr/invoice/${this.piId}`, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    paramUpload() {
      var form = new FormData()
      form.append('pi_id', this.piId)
      form.append('file', this.file)
      return form
    },
    paramImageS3(currPage) {
      var form = new FormData()
      form.append('pdf_name', this.fileName)
      form.append('page', currPage)
      return form
    },
    paramImage64(image64, text_type) {
      var form = new FormData()
      form.append('image64', image64)
      form.append('text_type', text_type)
      return form
    },
    paramDocNum(companyID) {
      return {
        company_id: companyID,
        doc_type:  "VUPO"
      };
    },
    getDocNum(companyID) {
      if (this.dataAll.priority === null && !this.$store.state.user.currentUser) {
        return
      }
      let priority = this.dataAll.priority;
      let priorityCode = "BRS"
      if (priority === "urgent" || priority === "very urgent") {
        priorityCode = "VU";
      }else if (priority ===null || priority.toString()==="") {
        priorityCode = ""
      }
      if (priorityCode===""){
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Priority is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      let orderType = "NPO"; // Default untuk non-PO
      if (this.dataValid.po.toString().length> 3 ) {
        orderType = "PO";
      }

      let doc_type = `${priorityCode}${orderType}`
      const params = {
        company_id:  this.$store.state.user.currentUser.company_id,
        doc_type:   doc_type
      };
      this.$vs.loading()
      this.$http
        .get("/api/v1/galang-docnum", {params}  )
        .then((resp) => {
          if (resp.data) {
            let barcode = this.generateBarcodeNumber(resp.data.doc_full_num , this.dataAll.priority, orderType === "PO");
            this.dataAll.barcode = barcode;
          }
          this.$vs.loading.close()
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getSupplierData(companyID) {
      if (companyID === null) {
        return
      }
      this.$vs.loading()
      this.$http
        .post("/api/v1/supplier", this.paramDocNum(), null)
        .then((resp) => {
          console.log(resp)
          this.$vs.loading.close()
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    isEmptyObject(obj) {
      return JSON.stringify(obj) === '{}';
    },
    getTextOcr(image64,varData) {
      if (image64 === null) {
        return
      }
      this.$vs.loading()
      this.$http
        .post("/api/v1/ocr/image64", this.paramImage64(image64, "TEXT"), null)
        .then((resp) => {
          this.ocr_value = resp.data;
          if (varData==="dpp_invoice") {
             this.ocr_value =  this.ocr_value.replaceAll(/[^0-9.]/g, '')
             this.validateInputDppInvoice( this.ocr_value);
          }else if (varData==="barcode") {
             this.dataAll[varData] = this.ocr_value;
          }else{
            if (this.dataAll[varData]) {
              this.dataAll[varData] = this.ocr_value;
            }else {
              this.dataValid[varData] = this.ocr_value;
            }
          }
          this.$vs.loading.close()
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    generateBarcodeNumber(runningNumberStr, priority, isPO) {
        let priorityCode = "2"; // Default untuk Standard priority
        if (priority === "urgent" || priority === "very urgent") {
          priorityCode = "1";
        }

      let orderType = "NN"; // Default untuk non-PO
      if (isPO) {
        orderType = "NP";
      }


      // Menghasilkan barcode number
      const barcode = `${runningNumberStr}MSDN-${orderType}${priorityCode}`;

      return barcode;
    },
    renameOrAddAttributes(arr, attrMap) {
      // Check if the input is an array
      if (!Array.isArray(arr)) {
        arr= [arr] ;
      }

      if (typeof attrMap !== 'object' || attrMap === null) {
         return null
      }

      return arr.map(item => {
        if (typeof item !== 'object' || item === null) {
          return null
        }

        const newItem = { ...item }; // Clone the item to avoid mutating the original item directly

        for (let oldAttr in attrMap) {
          const newAttr = attrMap[oldAttr];
          // eslint-disable-next-line no-prototype-builtins
          if (item.hasOwnProperty(oldAttr)) {
            newItem[newAttr] = item[oldAttr];
            delete newItem[oldAttr];
          } else {
            newItem[newAttr] = '0'; // Add new attribute with an empty value if old attribute doesn't exist
          }
        }

        return newItem;
      });
    },
     convertDate(dateStr) {
      // Periksa apakah formatnya sudah YYYY-MM-DD
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (regex.test(dateStr)) {
        return dateStr; // Format sudah benar, langsung return
      }

      let  formattedDate = "";
      if (dateStr.toString().indexOf("/")==-1){
        formattedDate = moment(dateStr).format('YYYY-MM-DD');
      }else {
        // Pisahkan string berdasarkan karakter "/"
        const [day, month, year] = dateStr.split('/');
        // Rekonstruksi menjadi format "YYYY-MM-DD"
        formattedDate = `${year}-${month}-${day}`;
      }
      return formattedDate;
    },
    formatNomorFaktur(nomorFaktur) {
        let part1 = nomorFaktur.slice(0, 3); // First 3 digits
        let part2 = nomorFaktur.slice(3, 5); // Next 2 digits
        let part3 = nomorFaktur.slice(5);    // Remaining digits

        return `${part1}-${part2}.${part3}`;
    },
    getTextQR(image64) {
      if (image64 === null) {
        return
      }
      this.$vs.loading()
      this.$http
        .post("/api/v1/ocr/qr64", this.paramImage64(image64, "TEXT"), null)
        .then((respose) => {
          this.dataAll.qr_link = respose.data;
          if (this.dataAll.qr_link){
            let respFaktur = JSON.parse(respose.raw);

              // this.getQRData(this.dataAll.qr_link);
            if (respFaktur.resValidateFakturPm) {
              let resp = respFaktur.resValidateFakturPm;
              let product_item = resp.detailTransaksi;
              const attrMap = {
                hargaSatuan: 'price',
                nama: 'item_name',
                jumlahBarang: 'quantity',
                diskon: 'discount',
                ppn: 'tax',
                dpp: 'price_discount',
                product_id: 'product_id',
                item_unit: 'item_unit',
              };
              const updatedData = this.renameOrAddAttributes(product_item, attrMap);
              this.dataValid.product_item = updatedData;
              this.dataAll.data_qr = resp;
              this.dataValid.dpp = resp.jumlahDpp;
              this.dataValid.tax = resp.jumlahPpn;
              this.dataValid.tax_date =  this.convertDate(resp.tanggalFaktur);
              this.dataValid.dph = resp.jumlahPpn;
              this.dataValid.nomor_seri = `${resp.kdJenisTransaksi}${resp.fgPengganti}.${this.formatNomorFaktur(resp.nomorFaktur)}`;
              this.dataValid.dndate = this.convertDate(resp.tanggalFaktur);
              this.getOptionSupplier();
              // "dndate":"2023-07-05","dph":"","dpp":""
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: "success get qr data",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              if (typeof this.dataValid.dpp !== "undefined") {
                this.validateInputDPP(this.dataValid.dpp)
              }

              if (typeof this.dataValid.tax !== "undefined") {
                this.validateInputTaxValue(this.dataValid.tax)
              }
              this.$forceUpdate();
            } else {
              this.$vs.notify({
                title: "Error",
                text: "Failed to get QR data",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }
          }else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get QR data",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$forceUpdate()
          this.$vs.loading.close()
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getQRData(link){
      this.$vs.loading();
      axios.get(link,  null)
          .then((resp) => {
            if (resp.kdJenisTransaksi) {
                this.dataAll.data_qr = resp;
                this.dataValid = resp;
                this.dataValid.dpp = resp.jumlahDpp ;
                this.dataValid.tax = resp.jumlahPpn ;
                this.dataValid.tax_date = resp.tanggalFaktur ;
                this.dataValid.dph = resp.jumlahPpn ;
                this.dataValid.nomor_seri = resp.nomorFaktur ;
                this.dataValid.dndate = resp.tanggalFaktur ;
                // "dndate":"2023-07-05","dph":"","dpp":""
                this.$forceUpdate();
            } else {
              this.$vs.notify({
                title: "Error",
                text: "Failed to get Supplier option",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get QR data",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.$vs.loading.close();
            console.log(error);
          });
    },
    getPdfS3Image(pages) {
      if (this.fileName === null || pages === null) {
        return
      }
      this.imageload = false;
      this.$vs.loading()
      this.$http
        .post("/api/v1/ocr/pdf-s3/image64", this.paramImageS3(pages), null)
        .then((resp) => {
          this.imageload = true;
          if (resp.data) {
            this.optionImageThub = resp.data;
          }else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.error,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          if (this.selectedImageIndex===-1){
            this.selectedImageIndex=0;
            this.previewImage(this.selectedImageIndex);
          }
          this.$vs.loading.close()
          // this.optionCutter.src = `data:image/png;base64,${resp}`
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    handleUpdate() {
      // this.status_submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.type_submit = "update";
          this.$vs.loading();
          this.postData();
        }
      });
    },
    getBarcode(regenerate){
      if (this.dataAll && this.dataAll.barcode.length <3)
      {
         regenerate  = true;
      }
      this.getDocNum(this.dataAll.supplier.ID.toString() );
      this.$forceUpdate();

    },
    handleScroll() {
      const targetDiv = this.$refs.targetDiv;
      const rect = targetDiv.getBoundingClientRect();
      this.isFixed = rect.top <= 0; // Menambahkan kelas fixed-div jika posisi div di atas viewport
    },
    priceFormat: function (amount) {
      const newAmount = amount
        .toString()
        .replace(/[^.\d]/g, "")
        .toString();
      console.log(amount, newAmount);
      amount = parseFloat(newAmount).toFixed(2);
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleInputGross(v) {
      // Remove any characters that are not digits, periods, or commas
      let input = String(v).replace(/[^\d.,-]/g, '');

      // Delay to ensure proper handling of input changes
      setTimeout(() => {
        if (input === '') {
          this.dataValid.gross = '';
          return;
        }

        // Detect the decimal and thousand separators based on the input format
        let commaCount = (input.match(/,/g) || []).length;
        let periodCount = (input.match(/\./g) || []).length;

        let thousandSeparator = periodCount > commaCount ? '.' : ',';
        let decimalSeparator = periodCount > commaCount ? ',' : '.';

        // Remove the thousand separators
        let amount = input.replace(new RegExp('\\' + thousandSeparator, 'g'), '');

        // Replace the decimal separator with a period for proper parsing
        amount = amount.replace(new RegExp('\\' + decimalSeparator, 'g'), '.');

        // Convert the cleaned string to a number
        amount = Number(amount);

        if (!isNaN(amount)) {
          // Format the number with thousand separators and a comma as the decimal separator
          this.dataValid.gross = amount.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        } else {
          this.dataValid.gross = 0;
        }
      }, 0);
    },
    checkPermission(permission) {
      try {
        return this.$store.getters && this.$store.getters['user/hasPermissions'] ? 
          this.$store.getters['user/hasPermissions'](permission) : true;
      } catch (error) {
        console.error('Error checking permission:', error);
        return true; // Default to true if there's an error
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.$vs.loading();
    try {
      await this.$store.dispatch("user/getPermissions","vendor-invoice-ocr");
    } catch (error) {
      console.error("Error getting permissions:", error);
      // Handle error appropriately
    }
    // this.$refs.grossInput.$el.querySelector('input').dispatchEvent(new Event('input'));
    setTimeout(() => {

      this.setMinMaxDates();
      if (this.piId && this.fileName) {
        if (this.dataAll.notes != null) {
          this.notes = this.dataAll.notes.toString().split(";")
        }
        if (typeof this.dataValid.gross !== "undefined") {
          this.validateInputGross(this.dataValid.gross)
        }

        if (typeof this.dataValid.dpp_invoice !== "undefined") {
          this.validateInputDppInvoice(this.dataValid.dpp_invoice)
        }

        if (typeof this.dataValid.dpp !== "undefined") {
          this.validateInputDPP(this.dataValid.dpp)
        }

        if (typeof this.dataValid.tax !== "undefined") {
          this.validateInputTaxValue(this.dataValid.tax)
        }


        if (typeof this.dataAll.data_note.bapb!= "undefined"){
            this.bapb =this.dataAll.data_note['bapb']
        }

        if (typeof this.dataAll.receive_at!= "undefined"){
          const date =  new Date(this.dataAll.receive_at );
          if (date.getFullYear() < 2020) {
            this.dataAll.receive_at = null;
          }
        }

        if (typeof this.dataAll.data_note==="undefined" || this.dataAll.data_note===""){
          this.dataAll.data_note =  {bapb:"",receiving_date:"",internal_memo:""}
        }

        this.getOptionSupplier();
        this.getRefferRole();
        this.getOptionSupplierBank();
        let pages = Array.from({ length: this.pageCount+1 }, (_, i) => i + 1).join(', ');

        this.getPdfS3Image(pages)
        this.table.inv_code = this.invCode != 0 ? this.invCode : null;
        this.$nextTick(() => {
          this.due_date = this.dueDate2
        });

      }
    }, 2000)


    this.canEdit = true;
  },
  computed: {
    computedTaxValue: {
        get() {
            return this.formatCurrency(this.dataValid.tax);
        },
        set(value) {
            this.$emit('update:dataValid.tax', value);
        }

    },
    computedGrossValid: {
        get() {
            return this.priceFormat(this.dataValid.gross);
        },
        set(value) {
            this.$emit('update:dataValid.gross', value);
        }

    },
    computedDppValue: {
      get() {
          return this.formatCurrency(this.dataValid.dpp);
      },
      set(value) {
          this.$emit('update:dataValid.dpp', value);
      }
    },
    computedDataValid: {
      get() {
        return this.dataValid;
      },
      set(value) {
        this.$emit('update:dataValid', value);
      }
    }
  },
  watch: {
    "bapb"(newVal){
        this.dataAll.data_note['bapb'] = newVal;
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set the time to the start of the day for accurate comparison
        const selectedDate = new Date(newVal);

        if (selectedDate > today) {
          const threeMonthsAgo = new Date();
          threeMonthsAgo.setMonth(selectedDate.getMonth() - 3);
          this.disabledDatesTaxDate.to = threeMonthsAgo;
          this.disabledDatesTaxDate.customPredictor= function(date) {
            return date.getDate() > threeMonthsAgo  ;
          }
        } else {
          this.setMinMaxDates();
        }
    },
    "dataValid.invoice_group"(newVal) {
        if (newVal){
          this.trade_type = "Trade"
        }else {
          this.trade_type = "Non Trade"
        }

    },
    piId() {
      Object.assign(this.$data, this.initialState());
      // this.getData();
      this.getPdfS3Image(1)
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
      this.$nextTick(() => {
        this.due_date = this.dueDate2
      });
    },
    "supplier.ID"(){
      this.getOptionSupplierBank()
    },
    // "this.dataValid.dndate"(){
    //   this.dataValid.duedate =
    // }
  },
};
</script>

<style scoped>

.c img {
  margin: 0.3rem 0.5rem;
}

.cc button {
  margin: 1rem 0.5rem;
}


.showBox {
  width: 600px;
  background: #dedede;
  border: 1px solid #dedede;
  border-radius: 5px;
  min-height: 50px;
}

.imgCutDownBoxContainer {
  border: 1px solid #ccc;
  background: #fff;
  text-align: left;
  overflow: hidden;
  border-radius: 5px;
}



.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
.fixed-div {
  position: fixed;
  top: 200px; /* Jarak dari atas halaman */
  left: 60px; /* Jarak dari kiri halaman */
  width: 47%; /* Lebar div */
  height: 200px; /* Tinggi div */
  padding: 10px; /* Padding dalam div */
  z-index: 1000; /* Z-index agar di atas elemen lain */
}
.target-div {
  height: 10px; /* Tinggi untuk membuat halaman scrollable */
  padding: 20px;
  margin-bottom: 20px;
}
.flex-container {
  display: flex;
}
.flex-item {
  padding: 0px;
}
.selected {
  border: 2px solid #968df3;
}
</style>
