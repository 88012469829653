<template>
  <vx-card title="Supplier Invoice OCR">

    <vs-button v-if="!detail" class="mb-2" @click="handleOpenForm">Upload</vs-button>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/3 w-full">
        <label class="vs-input--label">Invoice Date</label>
        <div class="vx-row">
          <div class="vx-col" style="width: 87%">
            <date-range-picker
                style="min-height: 40px"
                class="w-full"
                ref="picker"
                opens="center"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                :singleDatePicker="false"
                :timePicker="false"
                :timePicker24Hour="false"
                :showWeekNumbers="false"
                :showDropdowns="false"
                :autoApply="true"
                v-model="filter_inv_date"
                :linkedCalendars="true"
            >
              <template v-slot:input="picker" style="min-width: 350px">
                {{ dateFormat(picker.startDate) }} -
                {{ dateFormat(picker.endDate) }}
              </template>
            </date-range-picker>
          </div>
          <template>
            <vx-tooltip text="reset">
              <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
              />
            </vx-tooltip>
          </template>
        </div>
      </div>
      <div class="vx-col sm:w-1/3 w-full">
        <span>Supplier</span>
        <multiselect
            class="selectExample"
            v-model="filter_supplier"
            :options="optionSupplier"
            :multiple="false"
            :allow-empty="true"
            :group-select="false"
            :max-height="160"
            :limit="4"
            placeholder=" Type to search"
            track-by="ID"
            label="Name"
            :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
            </div>
          </template>
        </multiselect>
        <span
            class="text-danger text-sm"
            v-if="filter_supplier == null && status_submit"
        >This field is required</span>
      </div>
    </div>


    <div class="vx-row mb-12">
        <div v-if="!detail"
             v-bind:class="[
        detail ? detailShow + '  w-full mb-base' : '',
        detailHide,
      ]"
        >
        <vs-tabs>
          <vs-tab label="Draft">
            <div class="tab-text">
              <draft
                  :key="componentKey"
                  :supplier_code="supplier_code"
                  :from_inv_date="startDateInv"
                  :to_inv_date="endDateInv"
                  :from_due_date="startDateDue"
                  :to_due_date="endDateDue"
                  tab_status="draft"
                  :disabled_detail="false"
              ></draft>
            </div>
          </vs-tab>
          <vs-tab label="Released">
            <div class="tab-text">
              <draft
                  :key="componentKey"
                  :warehouse_code="warehouse_code"
                  :from_inv_date="startDateInv"
                  :to_inv_date="endDateInv"
                  :from_due_date="startDateDue"
                  :to_due_date="endDateDue"
                  tab_status="release"
                  :disabled_detail="true"
              ></draft>
            </div>
          </vs-tab>
          <vs-tab label="Canceled">
            <div class="tab-text">

                <draft
                        :key="componentKey"
                        :warehouse_code="warehouse_code"
                        :from_inv_date="startDateInv"
                        :to_inv_date="endDateInv"
                        :from_due_date="startDateDue"
                        :to_due_date="endDateDue"
                        tab_status="cancel"
                        :disabled_detail="true"
                ></draft>
            </div>
          </vs-tab>
          <vs-tab label="Rejected">
            <div class="tab-text">
                <draft
                        :key="componentKey"
                        :warehouse_code="warehouse_code"
                        :from_inv_date="startDateInv"
                        :to_inv_date="endDateInv"
                        :from_due_date="startDateDue"
                        :to_due_date="endDateDue"
                        tab_status="reject"
                        :disabled_detail="true"
                ></draft>
            </div>
          </vs-tab>
          <vs-tab label="Inquiry">
            <div class="tab-text">
              <draft
                :key="componentKey"
                :warehouse_code="warehouse_code"
                :from_inv_date="startDateInv"
                :to_inv_date="endDateInv"
                :from_due_date="startDateDue"
                :to_due_date="endDateDue"
                tab_status="inquiry"
                :disabled_detail="false"
              ></draft>
            </div>
          </vs-tab>
          <vs-tab label="LAF">
          <div class="tab-text">
            <laf
              :key="componentKey"
              tab_status="laf"
              :disabled_detail="false"
            ></laf>
          </div>
        </vs-tab>
        </vs-tabs>
      </div>

      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
          detail ? detailShow + '  w-full mb-base' : '',
          detailHide,
        ]"
        >
          <vs-row>
            <vs-col
                style="padding-bottom: 8px"
                vs-offset="8"
                vs-type="flex"
                vs-justify="rigth"
                vs-align="rigth"
                vs-w="4"
            >
              <vs-button
                  class="ml-auto"
                  size="small"
                  v-on:click="handleClose"
                  color="grey"
                  icon-pack="feather"
                  icon="icon-x-square"
              >Close
              </vs-button
              >
            </vs-col>

            <!-- form component -->
            <form-create @close="handleClose"></form-create>
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import Draft from "./draft"
import Laf from "./laf"
import FormCreate from "./form-create";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    Draft,
    FormCreate,
    Datepicker,
    DateRangePicker,
    Laf
  },
  data() {
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      dn_id: "",
      dn_code: "",
      cn_number: "",
      cn_date: "",
      tr_number: "",
      tr_date: "",
      componentKey: 0,
      warehouse_code: null,
      filter_warehouse: null,
      filter_inv_date: {
        startDate: null,
        endDate: null,
      },
      filter_due_date: {
        startDate: null,
        endDate: null,
      },
      status_submit: false,
      optionFilterWarehouse: [],
      startDateInv: null,
      endDateInv: null,
      startDateDue: null,
      endDateDue: null,
      optionSupplier: [],
      filter_supplier: null,
    };
  },
  methods: {
    handleResetInvoiceDateFilter() {
      this.filter_inv_date.startDate = null;
      this.filter_inv_date.endDate = null;
      this.startDateInv = null;
      this.endDateInv = null;
      this.componentKey += 1;
    },
    handleResetInvoiceDueDateFilter() {
      this.filter_due_date.startDate = null;
      this.filter_due_date.endDate = null;
      this.startDateDue = null;
      this.endDateDue = null;
      this.componentKey += 1;
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("dddd, MMMM Do YYYY");
      }
      return a;
    },
    getDatabyID(){
      this.$http
          .get("/api/v1/ocr/invoice/1", null)
          .then((resp) => {
            console.log("resp",resp)
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    getOptionSupplier() {
      this.$vs.loading();
      this.$http
          .get("/api/v1/master/suppliers", {
            params: {
              order: "name",
              sort: "asc",
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              let allSupplier=  {
                "ID": 0,
                "Code": "",
                "ShortName": "",
                "Name": "ALL SUPPLIER",
                "Address": "",
                "City": "",
                "ContactName": "",
                "ContactMobile": "",
                "Phone": "",
                "PaymentTermID": 0,
              }

              this.optionSupplier = [allSupplier, ...resp.data.records];
            } else {
              this.$vs.notify({
                title: "Error",
                text: "Failed to get Supplier option",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }
            this.$vs.loading.close();
          });
    },
    handleClose() {
      this.detail = false;
      this.componentKey += 1;
    },
    handleOpenForm() {
      this.detail = true;
      this.status_detail = "form-create";
    },
  },
  async mounted() {
    // Lebih aman jika ditambahkan try-catch
    try {
      await this.$store.dispatch("user/getPermissions", "vendor-invoice-ocr");
    } catch (error) {
      console.error("Error getting permissions:", error);
      // Handle error appropriately
    }    this.getOptionSupplier();
  },
  watch: {
    filter_inv_date: function () {
      if (this.filter_inv_date.startDate != null) {
        this.startDateInv = moment(this.filter_inv_date.startDate).format(
            "YYYY-MM-DD"
        );
        this.endDateInv = moment(this.filter_inv_date.endDate).format(
            "YYYY-MM-DD"
        );
      }
      this.componentKey += 1;
    },
    filter_supplier: function () {
      if (this.filter_supplier != null) {
        this.supplier_code = this.filter_supplier.Code;
      } else {
        this.supplier_code = null;
      }
      this.componentKey += 1;
    },
  },
};
</script>
<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}

.reportrange-text {
  min-height: 40px !important;
}
</style>
